
import { Component } from 'vue-property-decorator';
import {
  IUserProfileCreate,
} from '@/interfaces';
import { dispatchGetUsers, dispatchCreateUser, dispatchGetCustomers } from '@/store/admin/actions';
import { readSites, readUserProfile } from '@/store/main/getters';
import { readCustomers } from '@/store/admin/getters';
import VueBase from '@/components/VueBase.vue';

@Component
export default class CreateUser extends VueBase {
  public valid = false;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public siteCode: string = '';
  public customerId: string = '';

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await dispatchGetCustomers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.fullName = '';
    this.email = '';
    this.isActive = true;
    this.isSuperuser = false;
    this.siteCode = '';
    this.customerId = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      updatedProfile.password = this.password1;
      updatedProfile.site_code = this.siteCode ? this.siteCode : null;
      updatedProfile.customer_id = this.customerId ? this.customerId : null;
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push(`${this.prefixSiteUrl}/main/admin/users`);
    }
  }

  get sites() {
    return readSites(this.$store);
  }
  get customers() {
    return readCustomers(this.$store);
  }
  get hasCustomer() {
    const profile = readUserProfile(this.$store);
    return profile && profile.customer;
  }
}
